


















































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzDepositRecurringViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-view-model';

@Component({
  name: 'RecurringDeposit',
})
export default class RecurringDeposit extends Vue {
  deposit_recurring_view_model = Vue.observable(
    new AllianzDepositRecurringViewModel(this),
  );

  created() {
    this.deposit_recurring_view_model.initialize();
  }
}

