import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

import Vue from 'vue';

// Application
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';
import { SearchPeriodicityOfContributionsQuery } from '@/modules/my-investment/catalogs/allianz/periodicity-of-contribution/application/queries';

// Domain
import {
  MonthlyCollectionDayOptionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/monthly-collection-day-option-entity';
import {
  BankNameFormatter,
} from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/services';
import Inject from '@/modules/shared/domain/di/inject';
import { requiredRule } from '@/vue-app/utils/form-rules';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositRecurringViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_PERIODICITY_OF_CONTRIBUTION_QUERY)
  private readonly search_periodicity_of_contributions!: SearchPeriodicityOfContributionsQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view: Vue;

  private readonly customer_id = sessionStorage.getItem('user_id');

  i18n_namespace = 'components.allianz-dashboard.deposits.recurring_deposit';

  is_loading = false;

  is_valid_form = false;

  home_desired = false;

  policy_number = '';

  inputs = {
    source_account: '',
    destination_account: '',
    collection_day: 0,
    amount: '',
    new_collection_day: 0,
    new_amount: '',
    periodicity: '',
  };

  input_rules = {
    source_account: [
      requiredRule,
    ],
    new_collection_day: [
      requiredRule,
    ],
    new_amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) >= 500
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$500.00 MXN' })
      ),
      (value: string) => (
        parseFloat(value.replace(/[^0-9.-]/g, '')) <= 50000
        || this.translate_errors('utils.form-rules.maximum_error', { value: '$50,000.00 MXN' })
      ),
    ],
    periodicity: [
      requiredRule,
    ],
  };

  monthly_collection_days: Array<MonthlyCollectionDayOptionEntity> = [];

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  loadMonthlyCollectionDays = () => {
    const days = [...Array(28).keys()].map(
      (x) => ({
        key: x + 1,
        value: `${this.translate('day')} ${x + 1} ${this.translate('of_month')}`,
      }),
    );
    this.monthly_collection_days = days;
    this.monthly_collection_days.push({ key: 0, value: this.translate('recurring_not_defined') });
  }

  cancel = () => {
    this.view.$emit('endProcess');
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.inputs);
  }

  suspendContribution = () => {
    this.view.$emit('suspendContribution');
  }

  cleanForm = () => {
    this.inputs.new_collection_day = 0;
    this.inputs.new_amount = '';
  };

  initialize = async () => {
    this.is_loading = true;
    this.loadMonthlyCollectionDays();
    const {
      bank, account, policy_number_issued, monthly_collection_day, amount, home_desire,
    } = await this.get_allianz_account_query.execute({ customer_id: this.customer_id });
    const periods = await this.search_periodicity_of_contributions.execute();
    const formatted_bank_name = BankNameFormatter.format(bank);
    this.inputs.source_account = `${formatted_bank_name} **** ${account.substr(account.length - 4)}`;
    this.inputs.collection_day = monthly_collection_day || 0;
    this.inputs.amount = currencyFormat(amount || 0);
    this.policy_number = policy_number_issued;
    this.home_desired = home_desire;
    const monthly_period = periods.find((period) => period.descPeriod === '');
    this.inputs.periodicity = monthly_period?.descPeriod || 'MENSUAL';
    this.is_loading = false;
  }
}
