export default class BankNameFormatter {
  static format= (bank_name: string) => {
    let formatted_bank_name = bank_name;
    const internal_key_position = bank_name.indexOf('SW');
    const dom_position = bank_name.indexOf('(DOM');
    if (internal_key_position >= 0) {
      formatted_bank_name = formatted_bank_name.substring(0, internal_key_position);
    }
    if (dom_position >= 0) {
      formatted_bank_name = formatted_bank_name.substring(0, dom_position);
    }
    return formatted_bank_name;
  }
}
